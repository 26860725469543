async function initVue() {
	let appElements = document.querySelectorAll('[data-vue]');

    if (appElements.length > 0) {
        const {loadApp} = await import('./vue-app-loader');

        appElements.forEach(loadApp);
    }
}

document.addEventListener('DOMContentLoaded', function() {
	initVue();
});